// Categorii de locații
const LOCATION_TYPES = {
  CITY: 'city',
  SECTOR: 'sector',
  DISTRICT: 'district',
  SUBURB: 'suburb'
};

// Structura organizată a locațiilor
export const locations = {
  cities: [
    "bucuresti",
    "cluj-napoca", 
    "timisoara",
    "iasi",
    "constanta",
    "craiova",
    "brasov",
    "galati",
    "ploiesti",
    "oradea",
    "braila",
    "arad",
    "pitesti",
    "sibiu",
    "bolintin-vale",
    "bacau",
    "targu-mures",
    "baia-mare",
    "buzau",
    "botosani",
    "satu-mare",
    "suceava",
    "piatra-neamt",
    "drobeta-turnu-severin",
    "targu-jiu",
    "targoviste",
    "focsani",
    "bistrita",
    "resita",
    "tulcea",
    "calarasi",
    "giurgiu",
    "alba-iulia",
    "deva",
    "hunedoara",
    "zalau",
    "sfantu-gheorghe",
    "alexandria",
    "slobozia",
    "vaslui",
    "ovidiu",
    "medias",
    "roman",
    "turda",
    "ramnicu-valcea",
    "slatina",
    "miercurea-ciuc",
    "siret",
    "darabani",
    "barlad",
    "pascani",
    "radauti",
    "campulung-moldovenesc",
    "gura-humorului",
    "falticeni",
    "vatra-dornei",
    "vicovu-de-sus",
    "vicovu-de-jos",
    "liteni",
    "milisauti",
    "cajvana",
    "frasin",
    "broșteni",
    "dolhasca",
    "salcea",
    "solca",
    "arbore",
    "marginea",
    "putna",
    "moldovita",
    "sadova",
    "vama",
    "dornesti",
    "sinaia",
    "busteni",
    "azuga",
    "predeal",
    "reghin",
    "lugoj",
    "campina",
    "poiana-brasov",
    "baile-felix",
    "baile-herculane",
    "mangalia",
    "eforie-nord",
    "mamaia",
    "costinesti",
    "navodari",
    "sighisoara",
    "odorheiu-secuiesc",
    "gheorgheni",
    "toplita",
    "moinesti",
    "onesti",
    "comanesti",
    "caransebes",
    "oravita",
    "moldova-noua",
    "bailesti",
    "calafat",
    "segarcea",
    "harsova",
    "cernavoda",
    "medgidia",
    "techirghiol",
    "fetesti",
    "urziceni",
    "tandarei",
    "rosiori-de-vede",
    "zimnicea",
    "turnu-magurele",
    "caracal",
    "corabia",
    "draganesti-olt",
    "adjud",
    "panciu",
    "marasesti",
    "husi",
    "negresti",
    "stefanesti",
    "curtea-de-arges",
    "mioveni",
    "topoloveni",
    "codlea",
    "sacele",
    "zarnesti",
    "rasnov",
    "moreni",
    "pucioasa",
    "gaesti",
    "fieni",
    "borsa",
    "sighetu-marmatiei",
    "viseu-de-sus",
    "targu-lapus",
    "nasaud",
    "sangeorz-bai",
    "beclean",
    "salonta",
    "alesd",
    "marghita",
    "beius",
    "stei",
    "carei",
    "negresti-oas",
    "tasnad",
    "jibou",
    "simleu-silvaniei",
    "cehu-silvaniei",
    "ocna-mures",
    "aiud",
    "blaj",
    "sebes",
    "cugir",
    "campeni",
    "abrud",
    "hateg",
    "orastie",
    "simeria",
    "vulcan",
    "lupeni",
    "petrila",
    "petrosani",
    "uricani",
    "brad",
    "ineu",
    "chisineu-cris",
    "nadlac",
    "pecica",
    "santana",
    "lipova",
    "curtici",
    "neptun",
    "jupiter",
    "cap-aurora",
    "venus",
    "saturn",
    "olimp",
    "amara",
    "covasna",
    "sovata",
    "slanic-moldova",
    "slanic-prahova",
    "baile-tusnad",
    "baile-olanesti",
    "baile-govora",
    "calimanesti-caciulata",
    "ocna-sibiului",
    "moneasa",
    "durau",
    "borsec",
    "baile-1-mai",
    "ocna-sugatag",
    "baile-homorod",
    "ranca",
    "straja",
    "parang",
    "bran",
    "paltinis",
    "rucar",
    "fundata",
    "moieciu",
    "cheia",
    "lepsa",
    "sucevita",
    "voronet",
    "agapia",
    "varatec"
  ],
  
  bucharest: {
    sectors: [
      "Sector 1",
      "Sector 2",
      "Sector 3",
      "Sector 4",
      "Sector 5",
      "Sector 6"
    ],
    districts: [
      "Berceni",
      "Pantelimon",
      "Colentina",
      "Drumul Taberei",
      "Militari",
      "Rahova",
      "Giurgiului",
      "Titan",
      "Balta Albă",
      "Dorobanți",
      "Aviației",
      "Primăverii",
      "Tei",
      "Vitan",
      "Tineretului"
    ]
  },
  
  suburbs: [
    "Ștefăneștii de Jos",
    "Voluntari",
    "Chiajna",
    "Bragadiru",
    "Otopeni",
    "Buftea",
    "Mogoșoaia",
    "Corbeanca",
    "Domnești",
    "Popești-Leordeni",
    "Jilava",
    "Afumați",
    "Clinceni",
    "Cernica",
    "Snagov",
    "Găneasa",
    "1 Decembrie",
    "Berceni (Ilfov)",
    "Dragomirești-Vale"
  ]
};


// Lista completă a tuturor locațiilor
export const allLocations = [
  ...locations.cities,
  ...locations.bucharest.sectors,
  ...locations.bucharest.districts,
  ...locations.suburbs,
  "Ilfov"
];

// Funcții helper pentru manipularea locațiilor
export const getLocationType = (location) => {
  if (locations.cities.includes(location)) return LOCATION_TYPES.CITY;
  if (locations.bucharest.sectors.includes(location)) return LOCATION_TYPES.SECTOR;
  if (locations.bucharest.districts.includes(location)) return LOCATION_TYPES.DISTRICT;
  if (locations.suburbs.includes(location)) return LOCATION_TYPES.SUBURB;
  return null;
};

export const isInBucharest = (location) => {
  return locations.bucharest.sectors.includes(location) || 
         locations.bucharest.districts.includes(location);
};

export const isSuburb = (location) => {
  return locations.suburbs.includes(location);
};

export const getCitySlug = (location) => {
  if (!location) return '';
  
  // Mapping pentru diacritice și caractere speciale
  const diacriticsMap = {
    'ă': 'a',
    'â': 'a',
    'î': 'i',
    'ș': 's',
    'ş': 's',
    'ț': 't',
    'ţ': 't',
    'Ă': 'A',
    'Â': 'A',
    'Î': 'I',
    'Ș': 'S',
    'Ş': 'S',
    'Ț': 'T',
    'Ţ': 'T'
  };

  return location
    .toLowerCase()
    .split('')
    .map(char => diacriticsMap[char] || char)
    .join('')
    .replace(/[^a-z0-9]+/g, '-') // Înlocuiește orice non-alfanumeric cu -
    .replace(/^-+|-+$/g, '') // Elimină - de la început și sfârșit
    .replace(/-+/g, '-'); // Înlocuiește multiple - consecutive cu unul singur
};

export const capitalizeLocation = (location) => {
  if (!location) return '';
  
  // Împarte textul în cuvinte și capitalizează fiecare cuvânt
  return location
    .split(/[-\s]/)
    .map(word => {
      // Verifică dacă cuvântul este o paranteză sau este gol
      if (word === '' || word === '(' || word === ')') return word;
      
      // Cazuri speciale pentru prepoziții și articole
      const lowerCaseWords = ['de', 'la', 'pe', 'din', 'sub', 'lui'];
      if (lowerCaseWords.includes(word.toLowerCase())) return word.toLowerCase();
      
      // Capitalizează primul caracter și păstrează restul lowercase
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ')
    // Înlocuiește spațiile înapoi cu cratimă unde este necesar
    .replace(/ (?=Mare|Mures|Neamt|Secuiesc|Lapus|Oas|Ciuc|Muscel|Arges|Jiu|Magurele|Leordeni|Vale|Jos|Sus|Moldovenesc|Olt|Mures|Secuiesc|Lapus|Oas|Ciuc|Muscel|Arges|Jiu)/g, '-');
};

export const getDisplayLocation = (location) => {
  if (!location) return '';
  
  if (location === 'Ilfov') return location;
  
  const capitalizedLocation = capitalizeLocation(location);
  
  if (isInBucharest(location)) return `${capitalizedLocation}, București`;
  if (isSuburb(location)) return `${capitalizedLocation}, Ilfov`;
  
  return capitalizedLocation;
};

// Orașele principale pentru homepage cu descrieri SEO
export const mainCities = [
  {
    name: "București",
    description: "Găsește cei mai buni meseriași și profesioniști în București pentru renovări, reparații și amenajări. Servicii de calitate, prețuri competitive și expertiză locală garantată."
  },
  {
    name: "Cluj-Napoca",
    description: "Servicii profesionale de renovare și reparații în Cluj-Napoca. Meseriași verificați, experiență dovedită și satisfacție garantată pentru toate lucrările tale."
  },
  {
    name: "Timișoara",
    description: "Conectăm clienții din Timișoara cu cei mai buni meseriași locali. Servicii complete de amenajări, instalații și reparații la standarde înalte."
  },
  {
    name: "Iași",
    description: "Profesioniști verificați pentru orice tip de lucrare în Iași. De la renovări complete la reparații punctuale, găsești Meserias specializați pentru nevoile tale."
  },
  {
    name: "Constanța",
    description: "Servicii complete de amenajări și reparații în Constanța. Echipe profesioniste, materiale de calitate și rezultate garantate pentru locuința ta."
  },
  {
    name: "Brașov",
    description: "Găsește rapid meseriași profesioniști în Brașov pentru orice tip de lucrare. Servicii complete de amenajări și reparații la cele mai înalte standarde."
  }
];

export const cities = [
  "Alba Iulia",
  "Arad",
  "Bacău",
  "Baia Mare",
  "Bistrița",
  "Botoșani",
  "Brăila",
  "Brașov",
  "București",
  "Buzău",
  "Călărași",
  "Cluj-Napoca",
  "Constanța",
  "Craiova",
  "Deva",
  "Drobeta-Turnu Severin",
  "Focșani",
  "Galați",
  "Giurgiu",
  "Iași",
  "Miercurea Ciuc",
  "Oradea",
  "Piatra Neamț",
  "Pitești",
  "Ploiești",
  "Râmnicu Vâlcea",
  "Reșița",
  "Satu Mare",
  "Sfântu Gheorghe",
  "Sibiu",
  "Slatina",
  "Slobozia",
  "Suceava",
  "Târgoviște",
  "Târgu Jiu",
  "Târgu Mureș",
  "Timișoara",
  "Tulcea",
  "Vaslui",
  "Zalău",
  "Alexandria", // Teleorman
  "Botoșani",   // Deja prezent, dar verifică dublurile
  "Reșița",     // Deja prezent
  "Slobozia",   // Deja prezent
  "Giurgiu"     // Deja prezent
];
