const BASE_URL = 'https://meseriaslocal.ro';

export const citiesServicesData = {
  meta: {
    title: "Servicii Meseriași în Principalele Orașe 2025",
    description: "Găsește cei mai buni meseriași verificați în orașul tău. Servicii complete de instalații, construcții și renovări.",
    canonical: `${BASE_URL}/servicii-meseriasi-orase`,
    schema: {
      "@type": "Service",
      "name": "Servicii Meseriași în România",
      "areaServed": "România",
      "provider": {
        "@type": "Organization",
        "name": "MeseriașLocal"
      }
    }
  },
  cities: [
    {
      name: "București",
      slug: "bucuresti",
      region: "Muntenia",
      population: "2.1M"
    },
    {
      name: "Cluj-Napoca",
      slug: "cluj-napoca",
      region: "Transilvania",
      population: "324K"
    },
    {
      name: "Timișoara",
      slug: "timisoara",
      region: "Banat",
      population: "319K"
    },
    // ... alte orașe
  ],
  services: {
    instalatii: {
      id: "instalatii",
      title: "Instalații",
      slug: `${BASE_URL}/servicii/instalatii/sanitare`,
      description: `Servicii complete de <strong>instalații sanitare</strong>, <strong>electrice</strong> și <strong>termice</strong> pentru case și apartamente. 
      Echipele noastre de instalatori autorizați ANRE oferă soluții profesionale pentru orice tip de instalație.`,
      longDescription: {
        sanitare: {
          text: `Instalatorii noștri autorizați oferă servicii complete de instalații sanitare...`,
          link: `${BASE_URL}/servicii/instalatii/sanitare`
        },
        electrice: {
          text: `Servicii profesionale de instalații electrice realizate de electricieni autorizați ANRE...`,
          link: `${BASE_URL}/servicii/instalatii/electrice`
        },
        termice: {
          text: `Echipa noastră de specialiști în instalații termice oferă soluții complete...`,
          link: `${BASE_URL}/servicii/instalatii/termice`
        }
      },
      specialties: [
        {
          name: "Instalații sanitare",
          link: `${BASE_URL}/servicii/instalatii/sanitare`,
          icon: "🚰"
        },
        {
          name: "Instalații electrice",
          link: `${BASE_URL}/servicii/instalatii/electrice`,
          icon: "⚡"
        },
        {
          name: "Instalații termice",
          link: `${BASE_URL}/servicii/instalatii/termice`,
          icon: "🔥"
        }
        // ... alte specialități
      ],
      benefits: [
        {
          text: "Intervenții rapide 24/7 pentru urgențe",
          link: `${BASE_URL}/servicii/instalatii`
        },
        {
          text: "Echipa de specialiști în instalații",
          link: `${BASE_URL}/servicii/instalatii`
        },
        {
          text: "Calitatea și siguranța profesională garantată",
          link: `${BASE_URL}/servicii/instalatii`
        }
        // ... alte beneficii
      ]
        }
    // ... alte servicii
  },
  cta: {
    client: {
      text: "Caută meseriași verificați",
      link: `${BASE_URL}/new-post`,
      variant: "primary"
    },
    worker: {
      text: "Devino meseriaș partener",
      link: `${BASE_URL}/register/worker`,
      variant: "secondary"
    }
  }
};

export const getCitySlug = (cityName) => {
  const city = citiesServicesData.cities.find(c => c.name === cityName);
  return city ? city.slug : cityName
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
}; 