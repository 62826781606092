const BASE_URL = 'https://meseriaslocal.ro';

export const pricingData = {
  meta: {
    title: "Prețuri Orientative Servicii Meseriași 2025",
    description: "Lista actualizată de prețuri pentru servicii de renovări, instalații și amenajări interioare în România. Află costurile estimative pentru lucrările tale!",
    keywords: "preturi renovari, cost amenajari interioare, tarife meseriasi, preturi instalatii",
    canonical: `${BASE_URL}/preturi-servicii-meseriasi`,
    openGraph: {
      title: "Prețuri 2025 - Servicii Meseriași și Renovări",
      description: "Consultă lista actualizată de prețuri pentru renovări, instalații și amenajări interioare. Tarife transparente și competitive pentru toate tipurile de lucrări.",
      image: `${BASE_URL}/ceva.jpeg`,
      url: `${BASE_URL}/preturi-servicii-meseriasi`
    }
  },
  categories: [
    {
      id: "renovari-complete",
      title: "Renovări Complete",
      description: "Prețuri estimative pentru renovări complete de apartamente și case, incluzând manoperă pentru toate lucrările necesare.",
      slug: `${BASE_URL}/servicii/renovari-interioare`,
      schema: {
        "@type": "Service",
        "name": "Renovări Complete Apartamente și Case",
        "provider": {
          "@type": "LocalBusiness",
          "name": "MeseriașLocal"
        }
      },
      items: [
        { 
          id: "apartament-2-camere",
          service: "Apartament 2 camere (50mp)", 
          price: "15.000 - 25.000 RON",
          details: "Include <strong>zugrăveli</strong>, <strong>instalații</strong>, <strong>gresie/faianță</strong>, <strong>parchet</strong>, <strong>uși interioare</strong>",
          duration: "15-25 zile",
          link: `${BASE_URL}/servicii/renovari-interioare`,
          schema: {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Renovare Apartament 2 Camere"
            },
            "priceRange": "15000-25000 RON"
          }
        },
        { 
          id: "apartament-3-camere",
          service: "Apartament 3 camere (70mp)", 
          price: "20.000 - 35.000 RON",
          details: "Include <strong>renovare completă</strong> cu <strong>materiale standard</strong> și <strong>finisaje de calitate</strong>",
          duration: "20-30 zile",
          link: `${BASE_URL}/servicii/renovari-interioare/`,
          schema: {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Renovare Apartament 3 Camere"
            },
            "priceRange": "20000-35000 RON"
          }
        }
      ],
      features: [
        {
          text: "Evaluare gratuită la domiciliu",
          link: `${BASE_URL}/servicii/renovari-interioare`,
          schema: {
            "@type": "Offer",
            "name": "Evaluare Gratuită",
            "price": "0",
            "priceCurrency": "RON"
          }
        },
        {
          text: "Plan detaliat de execuție",
            link: `${BASE_URL}/servicii/planificare-lucrari`
        },
        {
          text: "Echipe dedicate de meseriași",
          link: `${BASE_URL}/servicii/renovari-interioare`
        },
        {
          text: "Garanție pentru lucrări",
          link: `${BASE_URL}/garantie-lucrari`
        }
      ]
    },
    {
      id: "instalatii",
      title: "Instalații",
      description: "Servicii complete de instalații sanitare, electrice și termice, realizate de echipe autorizate.",
      slug: `${BASE_URL}/servicii/instalatii`,
      schema: {
        "@type": "Service",
        "name": "Servicii Instalații",
        "provider": {
          "@type": "LocalBusiness",
          "name": "MeseriașLocal"
        }
      },
      items: [
        {
          id: "instalatie-sanitara",
          service: "Instalație sanitară baie",
          price: "2.000 - 4.000 RON",
          details: "Include <strong>înlocuire completă țevi</strong>, <strong>montaj obiecte sanitare</strong>",
          duration: "2-4 zile",
          link: `${BASE_URL}/servicii/instalatii/sanitare`,
          schema: {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Instalație Sanitară Baie"
            },
            "priceRange": "2000-4000 RON"
          }
        }
        // ... alte items pentru instalații
      ],
      features: [
        {
          text: "Intervenții de urgență 24/7",
          link: `${BASE_URL}/servicii/instalatii`
        },
        {
          text: "Echipe autorizate ANRE",
          link: `${BASE_URL}/servicii/instalatii`
        }
        // ... alte features
      ]
    }
    // ... alte categorii
  ]
};